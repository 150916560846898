import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
// import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props} locale='zh-CN'>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='欢迎联系我们'
          subheader='defi.to 致力于网罗区块链全生态价值DeFi项目，用专业的内容输出打造用户认识DeFi、了解DeFi、玩转DeFi的首选平台。
因此，不管您是用户/开发者/项目方，若您有任何疑问/建议/合作意向，我们都欢迎您通过提交下方表单联系我们 ，共同构建连接DeFi项目与用户之间的首选桥梁。'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        {/* <ContactInfo /> */}
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
